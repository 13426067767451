import styled, { css } from 'styled-components';

import { StyledText } from '../Text/style';

export const StyledModal = styled.section`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 25;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.backdrop};
  color: ${({ theme }) => theme.darkest};
`;

export const StyledModalWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 70px 15px;
  max-height: 100%;
`;

export const StyledModalInner = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${(props) =>
    props.modalsize || '744px'}; //если не передаем размер через пропсы, то задается дефолтная ширина
`;

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  /* overflow-x: hidden; */
  border-radius: 4px;
  padding: 32px 59px;
  width: calc(100% - 59px); // минус ширина крестика и отступа
  box-shadow: ${({ theme }) => theme.shadowModal};
  background-color: ${({ theme }) => theme.lightest};

  & form {
    display: contents;
  }

  @media (max-width: 767px) {
    padding: 24px;
    width: 100%;
  }
`;

export const StyledModalHeader = styled.div`
  display: grid;
  align-items: flex-start;
  grid-gap: 8px 16px;
  grid-template-columns: 1fr max-content;
  padding: ${(props) => props.padding || '0px'};
`;

// если в заголовке будет кнопка или иконка (как в хранилище)
export const StyledModalTitleWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 8px;
  align-items: center;
`;

export const StyledModalTitle = styled(StyledText)`
  grid-column: 1 / 2;
`;

export const StyledCloseModalButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  border-radius: 4px;
  padding: 0;
  width: 35px;
  height: 35px;
  box-shadow: ${({ theme }) => theme.shadowModal};
  background-color: ${({ theme }) => theme.lightest};
  margin-left: auto;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 15px;
    height: 2px;
    background-color: ${({ theme }) => theme.darkest};
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: background-color 0.3s ease-in-out;
  }

  &::after {
    right: 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: ${({ theme }) => theme.error};
    }
  }

  @media (max-width: 767px) {
    position: relative;
    flex: 0 0 25px;
    border: 1px solid ${({ theme }) => theme.border};
    width: 25px;
    height: 25px;
    box-shadow: none;
    grid-column: -1/-2;
    grid-row: 1/2;
  }
`;

export const StyledModalHeaderButtonWrapper = styled.div`
  display: flex;
  grid-column: 2 / 3;
  gap: 8px;

  @media (max-width: 767px) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
`;

export const StyledModalHeaderButton = styled.button`
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  padding: 1px 8px;
  width: max-content;
  color: ${({ theme }) => theme.darkest};
  font-size: 12px;
  line-height: 20px;
  background-color: ${({ theme }) => theme.light};
  transition: all 0.3s;

  &:hover {
    filter: brightness(95%);
  }
`;

export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;

  @media (min-width: 768px) {
    gap: 12px 24px;
  }
`;

export const StyledModalSubtitle = styled(StyledText)`
  font-weight: 500;
  & sup {
    color: ${({ theme }) => theme.warning};
    font-weight: 400;
  }
`;

export const StyledModalStatusInfo = styled.div`
  padding: 16px 60px;
  background-color: ${({ theme }) => theme.mainLight};
  margin: -8px -60px 0;

  ${(props) =>
    props.color === 'success' &&
    css`
      background-color: ${({ theme }) => theme.successLight};
    `}

  ${(props) =>
    props.color === 'warning' &&
    css`
      background-color: ${({ theme }) => theme.warningLight};
    `}

  ${(props) =>
    props.color === 'error' &&
    css`
      background-color: ${({ theme }) => theme.errorLightest};
    `}

  ${(props) =>
    props.color === 'light' &&
    css`
      background-color: transparent;
    `}
`;

// врапперы для элементов модалки (если нужно поставить два инпута в одну строку)
export const StyledModalRowEnd = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: flex-end;

  @media (max-width: 578px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledModalRowStart = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: flex-start;

  @media (max-width: 578px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledModalRowCenter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: center;

  @media (max-width: 578px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledModalFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
`;

export const StyledModalLabelInfo = styled.p`
  color: ${({ theme }) => theme.dark};
  font-size: 12px;
  line-height: 20px;
`;

export const StyledModalValueInfo = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const StyledModalIconButtonWrapper = styled.span`
  display: flex;
  gap: 8px;
  margin-left: auto;
`;

export const StyledModalScrollBox = styled.ul`
  max-height: 420px;
  overflow-y: auto;
`

export const StyledModalScrollItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 8px;
  transition: all 0.3s ease-in-out;
  
  &:hover {
    background-color: ${({theme}) => theme.mainLight};
  }
`