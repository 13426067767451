import styled, { css } from 'styled-components';
import { Button, IconButton, StyledModalHeaderButton, Checkbox } from '@shared/components';
import { StyledIconButton } from '@shared/components/IconButton/style';

export const StyledControls = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  gap: 16px;

  ${(props) =>
    props.$active &&
    `
      ${StyledMessagePin},
       ${StyledMessageDots} {
        opacity: 1;
      }
  `}
`;
export const StyledChatMessage = styled.div`
  grid-column: 1/-1;
  align-items: start;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr max-content;
  padding: 16px 59px;
  transition: all 0.3s;

  &:hover ${StyledControls} {
    * {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    padding: 16px;
  }

  ${(props) => props.$marked && `background-color: ${props.theme.mainLight};`}

  ${(props) => props.$highlighted && css`
    animation: flash 2s 1 forwards;

    @keyframes flash {
      0%, 75% {
        background-color: ${props.theme.mainLight};
      }
      100% {
        background-color: ${props.theme.lightest};
      }
    }
  `}
  
  ${(props) => props.$marked && css`
    ${StyledMessageBody}:after {
      background: linear-gradient(0deg, #BAE7FF, transparent);
    }
  `}
  
  ${(props) => props.$selected && `grid-template-columns: 20px 1fr max-content;`}
  
  ${(props) => props.$style && {...props.$style}}
`;

export const StyledMessageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  position: relative;
  transform: translateX(${props => props.messagePositionOnSwipe}px);
  
  ${props => props.messagePositionOnSwipe > 0 && css`
    &::before {
      content: "";
      position: absolute;
      background-image: url(${props.icon});
      left: -50px;
      top: 50%;
      transform: translateY(-50%);
      width: 25px;
      height: 25px;
      background-size: cover;
    }
  `}
`

export const StyledMessageHeader = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledMessageBody = styled.div`
  margin: 8px 0 8px 44px;
  transition: max-height 0.1s;
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-row-start: 2;
  grid-column: span 2;

  ${({ $state }) =>
    $state === 'show' &&
    `
    max-height: 100%;`}

  ${({ $state }) =>
    $state === 'hide' &&
    `
    max-height: 65px;
    overflow: hidden;
    position: relative;
    
    &::after {
    background: linear-gradient(0deg, #fff, transparent);
    bottom: 0;
    content: '';
    height: 35px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  `}
`;

export const StyledMessageInfo = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

export const StyledMessageUsername = styled.p`
  font-weight: 600;
  margin-right: 8px;
`;

export const StyledMessageDate = styled.p`
  color: ${({ theme }) => theme.dark};
  margin-right: 24px;
  grid-row: 2/3;
  grid-column: 1 / -1;
`;

export const StyledThumbWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;

  ${StyledChatMessage}:hover & {
    opacity: 1;
    visibility: visible;
  }

  @media (min-width: 768px) {
    margin: 0;
    position: static;
    opacity: 1;
    visibility: visible;
  }
`;

export const StyledThumb = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  margin-right: 12px;
  padding: 0;
  white-space: nowrap;

  &:hover {
    filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
  }

  ${(props) =>
    props.$active &&
    `
    filter: invert(50%) sepia(29%) saturate(7072%) hue-rotate(190deg) brightness(100%) contrast(101%);
    animation-duration: 1s;
    animation-name: bounceIn;
  `} @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
`;

export const StyledThumbIcon = styled.span`
  height: 18px;
  margin-right: 4px;
  width: 18px;
  background-image: url(${({ icon }) => icon});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const StyledEditMessageButton = styled(StyledModalHeaderButton)`
  margin-right: 24px;
  pointer-events: none;
`;

export const StyledDeletedMessage = styled.div`
  background-color: ${({ theme }) => theme.errorDark};
  border: 1px solid ${({ theme }) => theme.error};
  border-radius: 2px;
  color: ${({ theme }) => theme.lightest};
  display: inline-block;
  margin-right: 8px;
  padding: 1px 8px;
  width: max-content;
`;

export const StyledMessageLink = styled.span`
  color: ${({ theme }) => theme.main};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledTagsWrapper = styled.div`
  margin-bottom: 4px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StyledTag = styled.span`
  padding-left: 12px;
  position: relative;

  &::before {
    border-radius: 50%;
    content: '';
    height: 6px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;

    ${(props) =>
      props.color === 'blue' &&
      css`
        background-color: ${props.theme.main};
      `}

    ${(props) =>
      props.color === 'red' &&
      css`
        background-color: ${props.theme.error};
      `}

    ${(props) =>
      props.color === 'green' &&
      css`
        background-color: ${props.theme.success};
      `}

    ${(props) =>
      props.color === 'yellow' &&
      css`
        background-color: ${props.theme.warningSecondary};
      `}

    ${(props) =>
      props.color === 'grey' &&
      css`
        background-color: ${props.theme.disabled};
      `}
  }
`;

export const StyledQuoteWrapper = styled.section`
  background-color: ${({ theme }) => theme.light};
  margin-bottom: 8px;
  padding: 4px 12px;
`;

export const StyledQuoteBody = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
  padding: 8px 25px 8px 0;
  position: relative;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledQuoteDeleteButton = styled(IconButton)`
  background-color: transparent;
  border: none;
  height: 20px;
  position: absolute;
  right: 0;
  top: 8px;
  width: 20px;
`;

export const StyledQuote = styled.section`
  margin-bottom: 8px;
  cursor: pointer;
  border-left: 1px solid ${({ theme }) => theme.main};
  padding: 0 16px;
`;

export const StyledQuoteName = styled.span`
  color: ${({ theme }) => theme.dark};
  display: inline-block;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 20px;
`;

export const StyledQuoteDate = styled.span`
  color: ${({ theme }) => theme.dark};
  display: inline-block;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 20px;
`;

export const StyledResultTitle = styled.div`
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.main};
  }
`;

export const StyledResultButtonWrapper = styled.div`
  margin-left: 44px;
  margin-top: 8px;
  display: flex;
  gap: 8px;
`;

export const StyledResultButton = styled(Button)`
  padding: 1px 8px;
  font-size: 12px;
  line-height: 20px;
`;

export const StyledResultStatus = styled.p`
  border-radius: 2px;
  padding: 1px 8px;
  width: max-content;
  font-size: 12px;
  line-height: 20px;
  cursor: default;

  ${(props) =>
    props.status === 'pending' &&
    css`
      color: ${(props) => props.theme.main};
      border: 1px solid ${(props) => props.theme.main};
    `}

  ${(props) =>
    props.status === 'decline' &&
    css`
      color: ${(props) => props.theme.warning};
      border: 1px solid ${(props) => props.theme.warning};
    `}

  ${(props) =>
    props.status === 'accept' &&
    css`
      color: ${(props) => props.theme.success};
      border: 1px solid ${(props) => props.theme.success};
    `}
`;

export const StyledReadMoreButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.main};
  cursor: pointer;
  display: block;
  margin-left: 44px;
  margin-top: 4px;
  padding: 0;
  text-decoration: underline;
  grid-row: 4;
  text-align: left;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledMessageAttach = styled.div`
  margin-left: 44px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  grid-row-start: 3;
`;

export const StyledControlWrapper = styled.div`
  position: relative;
`;

export const StyledMessagePin = styled(IconButton)`
  opacity: 0;
  transition: all 0.3s;

  ${(props) =>
    props.$active &&
    `
    opacity: 1;
  `}
`;

export const StyledMessageDots = styled(StyledIconButton)`
  opacity: 0;
  transition: all 0.3s;
`;

// export const StyledMessageDots = (props) =>

export const MessageCheckbox = styled(Checkbox)`
  margin-top: 16px;
`;
