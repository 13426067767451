import React, { useState, useMemo, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal } from '@shared/components/Modal/Modal';
import {
  StyledModalLabelInfo,
  StyledModalRowCenter,
  StyledModalRowStart,
  StyledModalValueInfo,
} from '@shared/components/Modal/style';
import { Container } from '@shared/components';
import useAuth from 'src/hooks/useAuth';
import EmployeesForm from 'src/components/employees/employees-form/EmployeesForm';
import Preloader from 'src/components/preloaders/Preloader';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import AttachedFile from 'src/components/files/AttachedFile';
import UserStatus from 'src/components/employees/UserStatus';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { errorTypes, processResponseErrors } from 'src/utilize/processResponseErrors';
import { url_delete_emplyee } from 'src/settings/base-url';
import { getUsers } from 'src/redux/features/usersSlice';
import { assignIconLink, useEventTriggerOnEscPress } from 'src/utilize/helper-functions';
import { formatTelegramHandle } from '@shared/utils/formatTelegramHandle';

import EmploymentStatus from '../employees/EmploymentStatus';

import { StyledProfileAttach, StyledProfileUsername, StyledProfileUserStatus } from './style';

const UserProfileModal = ({
  onClose,
  setDismissalModalType,
  userId,
  employeeData,
  routeMatchDepartments,
  isLoading,
  getUserData,
}) => {
  const { t } = useTranslation();
  const translateKey = 'common.rights'; // для перевода
  const navigate = useNavigate();
  let location = useLocation();
  const auth = useAuth();

  const userHasRight = useMemo(() => auth?.isUserRightful(auth?.rightTypes.salary_view), [auth]);
  const canSeeRights = useMemo(() => auth?.isUserRightful(auth?.rightTypes.team_edit), [auth]);
  const canSeeHiddenFields = useMemo(() => auth?.isUserRightful(auth?.rightTypes.team_hidden_fields), [auth]);

  // статус модалки для редактирования
  const [modalEdit, setModalEdit] = useState(false);
  // подтверждаем удаление сотрудника
  const [confirmRemove, setConfirmRemove] = useState(false);

  const hasRightToEdit = useMemo(() => {
    return auth.isUserRightful(auth.rightTypes.team_edit);
  }, [auth]);

  const {
    first_name,
    last_name,
    date_start,
    birthday,
    contacts_phone,
    contacts_address,
    passport_visible,
    passport_hidden,
    contacts_other,
    email,
    // passport_hidden,
    // passport_visible,
    status,
    position,
    department,
    head_first_name,
    head_last_name,
    telegram,
  } = useMemo(() => {
    if (employeeData?.employee[0]) return employeeData?.employee[0];
    return {};
  }, [employeeData]);

  const { employee_salaries, employee_rights } = useMemo(() => {
    if (employeeData) {
      return employeeData;
    }
    return {};
  }, [employeeData]);

  const handlerEdit = () => {
    setModalEdit(true);
  };

  const handlerDelete = () => {
    setConfirmRemove(true);
  };

  const dispatch = useDispatch();
  const { showSnackbar } = useContext(SnackbarContext);

  const sendRemovedEmployee = () => {
    axios
      .delete(url_delete_emplyee, {
        data: { employee_id: userId },
      })
      .then(() => {
        dispatch(getUsers());
        if (onClose) onClose();
        else {
          const from = routeMatchDepartments ? '/team/departments' : '/team/users';
          navigate(from, { replace: true });
        }
      })
      .catch((error) => {
        const errorType = processResponseErrors(error);
        let errorMessage = 'Аккаунт пользователя привязан к объекту.';
        if (errorType === errorTypes.REFERENCE_MESSAGES) {
          errorMessage = 'У пользователя есть отправленные сообщения.';
        } else if (errorType === errorTypes.REFERENCE_FILES) {
          errorMessage = 'К пользователю прикреплены файлы.';
        }

        setConfirmRemove(false);
        showSnackbar(`Ошибка: ${errorMessage}`, 'fail');
      });
  };

  //  удаляем лишнее, что приходит с сервера с датой (время и тд)
  const formatDate = (dateString) => {
    if (dateString) {
      return dateString.substr(0, 10).replace(/-/g, '.').split('.').reverse().join('.');
    } else {
      return '';
    }
  };

  const headerButtons = [
    { name: 'Ред.', action: () => handlerEdit() },
    { name: 'Удалить', action: () => handlerDelete() },
    { name: 'Уволить', action: () => setDismissalModalType('create') },
  ];

  // при закрытии модалки отправляем на пред страницу
  const handlerCloseModal = () => {
    if (onClose) return onClose();
    let from = location.state?.backgroundLocation?.pathname || '/';
    navigate(from, { replace: true });
  };

  // функция для закрытия модального окна, при нажатии на кнопку Esc
  useEventTriggerOnEscPress(handlerCloseModal);

  return (
    <>
      {/* {page && page === "TeamList" ? <Team /> : <TeamTree />} */}
      <Modal
        title="Карточка сотрудника"
        onClose={handlerCloseModal}
        headerButtons={hasRightToEdit && headerButtons}
        withoutButtons={true}
      >
        {/* <StatusEmployee /> */}

        {isLoading ? (
          <Preloader />
        ) : (
          <>
            <EmploymentStatus
              employeeData={employeeData}
              getUserData={getUserData}
              setDismissalModalType={setDismissalModalType}
            />
            <StyledModalRowCenter>
              <UserStatus userId={userId} firstName={first_name} lastName={last_name} employeeData={employeeData} />
              <div>
                <StyledProfileUsername>
                  {last_name} {first_name}
                </StyledProfileUsername>
                <StyledProfileUserStatus>{(status && status) || ''}</StyledProfileUserStatus>
                <Container direction="column" gap="4px">
                  {employeeData?.employee_social?.length > 0 &&
                    employeeData?.employee_social.map((social, i) => {
                      if (!social.value) return '';
                      return (
                        <Container key={i} align="center">
                          {assignIconLink(social.type, social.value)}
                          <span>{social.value}</span>
                        </Container>
                      );
                    })}
                </Container>
              </div>
            </StyledModalRowCenter>

            <StyledModalRowStart>
              <div>
                <StyledModalLabelInfo>Дата рождения</StyledModalLabelInfo>
                <StyledModalValueInfo>{formatDate(birthday)}</StyledModalValueInfo>
              </div>
              {/*{userHasRight && (*/}
              {/*  <div>*/}
              {/*    <StyledModalLabelInfo>Зарплата</StyledModalLabelInfo>*/}
              {/*    {employee_salaries && <StyledModalValueInfo>{employee_salaries[0]?.salary}</StyledModalValueInfo>}*/}
              {/*  </div>*/}
              {/*)}*/}
            </StyledModalRowStart>
            <StyledModalRowStart>
              <div>
                <StyledModalLabelInfo>Должность</StyledModalLabelInfo>
                <StyledModalValueInfo>{position}</StyledModalValueInfo>
              </div>
              <div>
                <StyledModalLabelInfo>Электронная почта</StyledModalLabelInfo>
                <StyledModalValueInfo as="a" href={`mailto:${email}`}>
                  {email}
                </StyledModalValueInfo>
              </div>
            </StyledModalRowStart>
            <StyledModalRowStart>
              <div>
                <StyledModalLabelInfo>Отдел</StyledModalLabelInfo>
                <StyledModalValueInfo>{department}</StyledModalValueInfo>
              </div>
              <div>
                <StyledModalLabelInfo>Телефон</StyledModalLabelInfo>
                <StyledModalValueInfo as="a" href={`tel:${contacts_phone}`}>
                  {contacts_phone}
                </StyledModalValueInfo>
              </div>
            </StyledModalRowStart>
            <StyledModalRowStart>
              <div>
                <StyledModalLabelInfo>Руководитель</StyledModalLabelInfo>
                <StyledModalValueInfo as="a" href="link-to-profile">
                  {`${head_first_name || ''} ${head_last_name || ''}` || '----'}
                </StyledModalValueInfo>
              </div>
              <div>
                <StyledModalLabelInfo>Дата выхода на работу</StyledModalLabelInfo>
                <StyledModalValueInfo>{formatDate(date_start)}</StyledModalValueInfo>
              </div>
            </StyledModalRowStart>
            <StyledModalRowStart>
              <div>
                <StyledModalLabelInfo>Телеграм</StyledModalLabelInfo>
                <StyledModalValueInfo
                  as="a"
                  target="_blank"
                  rel="noopener"
                  href={`https://t.me/${formatTelegramHandle(telegram)}`}
                >
                  {telegram}
                </StyledModalValueInfo>
              </div>
              <div>
                <StyledModalLabelInfo>Другие контакты</StyledModalLabelInfo>
                <StyledModalValueInfo>{contacts_other || 'не указано'}</StyledModalValueInfo>
              </div>
            </StyledModalRowStart>
            <StyledModalRowStart>
              <div>
                <StyledModalLabelInfo>Адрес</StyledModalLabelInfo>
                <StyledModalValueInfo>{(contacts_address && contacts_address) || ''}</StyledModalValueInfo>
              </div>
            </StyledModalRowStart>
            <StyledModalRowStart>
              <div>
                <StyledModalLabelInfo>Паспортные данные (открытые для всех)</StyledModalLabelInfo>
                <StyledModalValueInfo>{passport_visible}</StyledModalValueInfo>
              </div>
              {canSeeHiddenFields && (
                <div>
                  <StyledModalLabelInfo>Паспортные данные (скрытые от всех)</StyledModalLabelInfo>
                  <StyledModalValueInfo>{passport_hidden}</StyledModalValueInfo>
                </div>
              )}
            </StyledModalRowStart>

            {canSeeRights && (
              <StyledModalRowStart>
                <div style={{ gridColumn: '1/-1' }}>
                  <StyledModalLabelInfo>Доступы</StyledModalLabelInfo>

                  {employee_rights?.length > 0 ? (
                    <div>
                      {employee_rights.map((el, i) => {
                        return <StyledModalValueInfo key={i}>{t(`${translateKey}.${el}`)}</StyledModalValueInfo>;
                      })}
                    </div>
                  ) : (
                    <StyledModalValueInfo>Базовые права</StyledModalValueInfo>
                  )}
                </div>
              </StyledModalRowStart>
            )}

            {Array.isArray(employeeData?.employee_files) && employeeData.employee_files.length > 0 && (
              <StyledProfileAttach>
                {employeeData?.employee_files?.map((file, i) => {
                  const url = `/employees/files/${employeeData.employee[0].id}/${file.file}`;
                  return <AttachedFile fileUrl={url} fileName={file.file} key={i} />;
                })}
              </StyledProfileAttach>
            )}
          </>
        )}

        {confirmRemove && (
          <ConfirmAction
            actionText="Уверены что хотите удалить?"
            confirm={sendRemovedEmployee}
            cancel={() => setConfirmRemove(false)}
            confirmButtonText="Удалить"
          />
        )}
      </Modal>

      {modalEdit && (
        <EmployeesForm
          employeeData={employeeData}
          onCloseModal={() => {
            setModalEdit(false);
            getUserData();
          }}
          action="edit"
        />
      )}
    </>
  );
};

export default UserProfileModal;
