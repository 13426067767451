import { shape, object, func } from 'prop-types';
import {useState} from "react";
import {ButtonWithIcon, Container, HrLine, IconButton, Title} from '@shared/components';
import { styled } from 'styled-components';
import iconAdd from '@assets/images/icons/plus.svg';
import iconFilter from "@assets/images/icons/filter.svg";

import { project_window } from '../../../settings/window-types';

import ProjectListFilter from './project-list-filter/ProjectListFilter';
// import ProjectListTabs from "./project-list-tabs/ProjectListTabs";
import ProjectListContainer from './project-list-container/ProjectListContainer';


ProjectList.propTypes = {
  popupWindow: shape({
    props: shape({
      data: object,
      onClose: func,
      onEdit: func,
      onOpen: func,
    }),
  }),
};

function ProjectList(props) {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  return (
    <StyledProjectList>
        <StyledProjectHeader>
          Проекты
          <Container gap={'12px'} align={'center'}>
            <IconButton
              style={{backgroundSize: '24px'}}
              size={40}
              background="#1890FF"
              icon={iconFilter}
              onClick={() => setIsFiltersOpen((isFiltersOpen) => !isFiltersOpen)}
            />
            <ButtonWithIcon
              color="green"
              position="left"
              icon={iconAdd}
              size={20}
              onClick={() => {
                props.popupWindow.props.onOpen(project_window);
              }}
            >Добавить проект
            </ButtonWithIcon>
          </Container>
        </StyledProjectHeader>

      {/*<ProjectListTabs />*/}
      {/* панель фильтрации проектов */}

      <HrLine />

      {isFiltersOpen && <ProjectListFilter />}

      {/* список проектов */}
      <ProjectListContainer {...props} />
    </StyledProjectList>
  );
}

export const StyledProjectList = styled.section`
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 24px;

  @media (max-width: 550px) {
    padding: 0 12px;
  }
`;

export const StyledProjectHeader = styled(Title)`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;
  align-items: center;
  
  @media (min-width: 578px) {
    grid-template-columns: 1fr max-content;
  }
`

export default ProjectList;
